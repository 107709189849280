import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Slide, DialogContent, Card, Dialog, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { PaystackButton } from "react-paystack";
import HttpService from "../../services/HttpService";
import Currency from "../../pipes/Currency";
import * as processHtml from "../../services/processHtml";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AuthService from "../../services/AuthService";
import { ErrorOutlined } from "@mui/icons-material";
import LoginModule from "../public/Login/LoginModule";
import BlockerTemplate from "./BlockerTemplate";
import Loading from "./Loading";
import ResendVerify from "./ResendVerify";
import CustomToast from "./CustomToast";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BookingModal = (props: any) => {
  const modal = props.data;
  console.log(props);

  const { decodeHtml } = processHtml;
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  const [loading_message, setLoadingMessage] = React.useState<any>("");
  const [screen_mode, setScreenMode] = React.useState<any>(null);
  const [screen_loaded, setScreenLoaded] = React.useState<any>(false);
  const [screen_loading, setScreenLoading] = React.useState<any>(false);
  const onToastClose = () => {
    setToast({ ...toast, onopen: false });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
  });

  React.useEffect(() => {
    setScreenLoaded(false);
    setScreenLoading(true);
    setLoading(true);
    setLoadingMessage("checking user status...");
    AuthService.checkSession()
      .then(
        (res) => {
          console.log("SESSION CHECK RESULT:: ", res);
          if (!res.status || res.status === 0) {
            setScreenMode("blocker_screen");
          } else {
            if (usr.is_verified === 0) {
              setScreenMode("verify_sreen");
            } else {
              setScreenMode("booking_sreen");
            }
          }
        },
        (error) => {
          setScreenMode("loading_sreen");
        }
      )
      .finally(() => {
        setScreenLoaded(true);
        setScreenLoading(false);
        setLoading(false);
      });
  }, []);

  const [pay_props, setPayProps] = React.useState<any>({
    attendance_mode: null,
  });
  const [pay_data, setPayData] = React.useState<any>({});
  const [transaction_id, setTxId] = React.useState<number>(0);
  const [pay_set, setPaySet] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pay_loaded, setPayLoaded] = React.useState<boolean>(false);
  React.useEffect(() => {
    setPayData(props.data);
    //start_transaction();
    console.log(pay_data);
  }, []);
  const [last_message, setLastMessage] = React.useState<any>(false);
  const [last_status, setLastStatus] = React.useState<any>(false);
  const onPaySuccess = (data: any) => {
    const obj = {
      ...pay_props,
      ...data,
    };
    setLoading(true);
    setLoadingMessage("Finalizing registration...");
    console.log(obj);
    HttpService.post({ mode: "save_booking", load: obj }, "events")
      .then(
        (res) => {
          //
          console.log(res);
          setLastStatus(res.status);
          setLastMessage(res.message);
          setLoadingMessage(res.message);
          setToast({
            ...toast,
            onopen: true,
            hideduration: 6500,
            message: res.message,
            severity: res.status === 0 ? "error" : "success",
            onclose: onToastClose,
          });
          if (res.status === 1) {
            setPayData({});
            setPayLoaded(false);
            setPayProps({});
            setLastMessage(true);
            setTimeout(() => {
              setToast({
                ...toast,
                onopen: false,
                onclose: onToastClose,
              });
              modal.onclose();
              setLastMessage(false);
            }, 6000);
          }
        },
        (error) => {
          console.log(error);
          setLoadingMessage(error.message);
          setToast({
            ...toast,
            onopen: true,
            message: error.message,
            onclose: onToastClose,
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const start_transaction = () => {
    setLoading(true);
    setPayLoaded(false);
    console.log(pay_data);
    HttpService.post({ mode: "start_booking", load: pay_data }, "events")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setPayLoaded(true);
            setPayProps({
              ...pay_props,
              email: modal.email,
              transaction_id: res.transaction_id,
              attendance_mode: "physical",
              reference: res.reference,
              metadata: {
                name: modal.name,
                phone: modal.phone,
              },
              publicKey: modal.public_key,
              text: "Pay Now",
              onSuccess: onPaySuccess,
              onClose: () => alert("Are you quitting the registration"),
            });
            setPaySet(true);
          } else {
            alert(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  let pay_chart: any = {
    virtual: pay_data.virtual_price,
    physical: pay_data.physical_price,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayLoaded(false);
    const val = (event.target as HTMLInputElement).value;
    console.log(val);
    setPayProps({
      ...pay_props,
      attendance_mode: val,
      amount: pay_chart[val] * 100,
    });
    setPayData({
      ...pay_data,
      amount: pay_chart[val],
    });
    console.log(pay_props);
    console.log(pay_data);
  };
  if (screen_loaded && screen_mode) {
    return (
      <>
        <Dialog
          fullScreen={false}
          TransitionComponent={Transition}
          open={modal.onopen}
          onClose={modal.onclose}
          scroll={"paper"}
          aria-labelledby={"Me"}
        >
          <DialogContent sx={{ p: "0", m: "0" }}>
            <section className="reg-modal-width">
              <Card sx={{ m: "0", p: "0" }}>
                <div className="card-header flex flex-row align-items-center">
                  <span className="px10">
                    <i className="fas fa-calendar"></i>
                  </span>
                  <span className="spacer">REGISTRATION</span>
                  <span className="px10">
                    <IconButton color="error" onClick={modal.onclose}>
                      <i className="fas fa-close"></i>
                    </IconButton>
                  </span>
                </div>
                {screen_mode === "booking_sreen" && (
                  <section className="relative" style={{ minHeight: "200px" }}>
                    <div className="modal-items-container">
                      {loading && <Loading loading_text={loading_message} />}
                      {!loading && (
                        <>
                          <div className="bordered mb flex flex-col px20 pt20 pb20 border-radius">
                            <FormControl
                              sx={{
                                display: "flex",
                                mb: "15px",
                              }}
                            >
                              <FormLabel id="demo-controlled-radio-buttons-group">
                                <h3 className="mb10"> Attendance Mode</h3>
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={pay_props.attendance_mode}
                                onChange={handleChange}
                              >
                                <FormControlLabel
                                  value="physical"
                                  control={<Radio />}
                                  label="Physical"
                                />
                                <FormControlLabel
                                  value="virtual"
                                  control={<Radio />}
                                  label="Virtual"
                                />
                              </RadioGroup>
                            </FormControl>
                            {pay_props.amount && (
                              <>
                                <div className="flex flex-row pt20 border-top">
                                  <div className="spacer boldest">
                                    <h3>Registration fee:</h3>
                                  </div>
                                  <div className="text-right">
                                    <h3>
                                      <Currency
                                        value={pay_props.amount / 100}
                                      />
                                    </h3>
                                  </div>
                                </div>
                                {!pay_loaded && (
                                  <div className="mb10 mt20 flex flex-col">
                                    <Button
                                      className=""
                                      variant="contained"
                                      size="large"
                                      onClick={start_transaction}
                                      disabled={loading}
                                    >
                                      {loading
                                        ? "Working..."
                                        : "Confirm your selection"}
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {pay_loaded && (
                            <div className="py20">
                              <PaystackButton
                                {...pay_props}
                                className="paystack-button"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {last_message && (
                      <Box
                        sx={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          zIndex: "90000",
                          justifyContent: "center",
                        }}
                      >
                        <div className="pxy20">
                          <i
                            style={{
                              fontSize: "45px",
                              color: last_status === 1 ? "green" : "tomato",
                            }}
                            className={
                              last_status === 1
                                ? "fas fa-check-circle"
                                : "fas fa-exclamation-tiangle"
                            }
                          ></i>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(last_message),
                          }}
                        ></div>
                      </Box>
                    )}
                  </section>
                )}

                {screen_mode === "blocker_screen" && (
                  <BlockerTemplate return_url={`/event/${modal.url}`} />
                )}
                {screen_mode === "verify_screen" && <ResendVerify user={usr} />}
              </Card>
            </section>
          </DialogContent>
        </Dialog>
        <CustomToast data={toast} />
      </>
    );
  } else if (!screen_loaded && screen_loading) {
    return (
      <>
        <Box
          sx={{
            p: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} />
          <div className="py20">
            <h3>Loading Booking Pane...</h3>
          </div>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            p: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "55px",
          }}
        >
          <ErrorOutlined color="error" />
        </Box>
        <div className="flex flex-col justify-content-center align-items-center py20">
          <h3>Registration Screen Not Loaded Properly</h3>
        </div>
      </>
    );
  }
};

export default React.memo(BookingModal);
