import React from "react";
import htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { Button } from "@mui/material";
import AuthService from "../../../services/AuthService";
import DatePipe from "../../../pipes/DatePipe";
import QRCode from "react-qr-code";
import { Download } from "@mui/icons-material";
import CertificateModal from "../../templates/CertificateModal";

const BookingCertificate = (props: any) => {
  const { data } = props;
  console.log(data);

  const usr = AuthService.getCurrentUser();
  const [node, setNode] = React.useState<any>(null);
  React.useEffect(() => {
    let ndx: HTMLElement | any = document.getElementById("certificate-node");
    if (ndx) {
      setNode(ndx);
      console.log(node);
      //genCert();
    }
  }, []);

  const [preview, setPreview] = React.useState<any>(null);
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
  });
  const [file_url, setFileUrl] = React.useState<any>(null);
  const [file_set, setFile] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const genCert = () => {
    setLoading(true);
    toJpeg(node /* , { width: 1200, height: 830 } */)
      .then(function (dataUrl) {
        setFileUrl(dataUrl);
        setFile(true);
        return;
        setLoading(false);
        /*   var img = new Image();
        img.src = dataUrl;
         let url = img.src.replace(
          /^data:image\/[^;]+/,
          "data:application/octet-stream"
        );window.open(url);
        return;  */

        setModal({
          ...modal,
          onopen: true,
          reference: data.reference,
          preview: dataUrl,
        });
        console.log(modal); /**/
        //setPreview(dataUrl);
        //document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };
  return (
    <React.Fragment>
      <div className="flex py10 mb10 border-bottom flex-row align-items-center">
        <span className="spacer"></span>

        <span className="py10">
          {!file_set && (
            <Button onClick={genCert} disabled={loading}>
              {loading ? "Working..." : "GENERATE CERTIFICATE FILE"}
            </Button>
          )}{" "}
          {file_set && (
            <a
              href={file_url}
              className="file-download-btn"
              download={`${data.reference}.jpg`}
            >
              <i className="fas fa-download"></i> DOWNLOAD CERTIFICATE
            </a>
          )}
        </span>
      </div>

      {preview && (
        <div className="banner-section">
          <div className="image_preview">
            <img className="" src={preview} alt="preview Image" />
          </div>
        </div>
      )}

      <div className="cert-cover">
        <div id="certificate-node">
          <section className="start-details text-center">
            <span className="cert-intro">
              This certificate is proudly presented to{" "}
            </span>
            <div className="w-80">
              <div className="name-div">
                {`Dr. ${usr.firstname} ${usr.surname}`}
              </div>
            </div>
            <div className="pb10 px20">
              <div className="desc-text">
                having fully and actively participated in the
              </div>
              <div className="title-text">
                {" "}
                <h2>{data.title}</h2>
              </div>
              <div className="desc-text">
                held from{" "}
                <strong>
                  <DatePipe value={data.start_date * 1000} full={true} />
                </strong>{" "}
                to{" "}
                <strong>
                  <DatePipe value={data.end_date * 1000} full={true} />
                </strong>
              </div>
            </div>
          </section>

          <div className="foot-items">
            <span className="cert-logo"></span>
            <div className="spacer flex flex-row px20 align-items-center justify-content-center">
              <div className="signature-box">
                <div className="signature-image">
                  <img src="/images/signature.png" alt="signature" />
                </div>
                <div className="signature-signer">Signed</div>
              </div>
              <span className="px30"></span>
              <span className="attended-conteiner">
                <img src="/images/attended.jpg" alt="logo" />
              </span>
              <span className="px30"></span>
              <div className="signature-box">
                <div className="signature-image">
                  <span className="date-box">
                    <DatePipe value={data.end_date * 1000} full={true} />
                  </span>
                </div>
                <div className="signature-signer">Issue Date</div>
              </div>
            </div>
            <span className="xc ">
              <span className="qrcontainer">
                {/*    <img src="/images/qrcode.png" alt="logo" /> */}

                {data && (
                  <QRCode
                    /* title={"qrc"} */
                    value={`${process.env.REACT_APP_SERVER_DOMAIN}verify-certificate/${data.id}-${data.reference}`}
                    bgColor={"#FFFFFF"}
                    fgColor={"#000000"}
                    size={80}
                  />
                )}
              </span>
            </span>
          </div>
          <div className="cert-no">
            <span className="spacer"></span>
            <span className="text-right">{`${data.id}-${data.reference}`}</span>
          </div>
        </div>
      </div>
      {modal.onopen && <CertificateModal data={modal} />}
    </React.Fragment>
  );
};

export default BookingCertificate;
