import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import TextField from "@mui/material/TextField";
import { DefaultEditor } from "react-simple-wysiwyg";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const EventNew = (props: any) => {
  console.log("New Event Renders");
  let val = props.data;

  let navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [categories_fetched, setCategoriesFetched] =
    React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [sub_theme_content, setSubThemeContent] = React.useState<string>("");
  const [summary, setSummary] = React.useState<string>("");
  const [loading_text, setText] = React.useState<string>("Save Now");
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [meta, setMeta] = React.useState<any>({ is_sponsored: "0" });

  React.useEffect(() => {
    if (!categories_fetched) {
      fetchCats();
    }
  }, []);

  const fetchCats = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("events", { mode: "list-categories" })
      .then(
        (resp) => {
          console.log(resp);
          if (Array.isArray(resp)) {
            setCategories(resp);
            setCategoriesFetched(true);
          } else {
            setCategories([]);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onSubThemeChange = (e: any) => {
    setSubThemeContent(e.target.value);
    console.log(content);
  };
  const onSummaryChange = (e: any) => {
    setSummary(e.target.value);
    console.log(summary);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("content", content);
    formData.append("mode", "create");
    formData.append("file", file);
    formData.append("icon", icon);
    formData.append("is_file", is_file);
    formData.append("is_icon", is_icon);
    formData.append("title", meta.title);
    formData.append("cat_id", meta.cat_id);
    formData.append("summary", summary);
    formData.append("theme", meta.theme);
    formData.append("sub_theme", sub_theme_content);
    formData.append("physical_price", meta.physical_price);
    formData.append("virtual_price", meta.virtual_price);
    formData.append("location_venue", meta.location_venue);
    formData.append("location_state", meta.location_state);
    formData.append("start_date", meta.start_date);
    formData.append("end_date", meta.end_date);
    //const obj: any = { ...meta, content: content, mode: "create" };
    //console.log(obj);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("events", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              navigate(`/admin/events/p/${resp.url}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [file, setFile] = React.useState<any>(null);
  const [icon, setIcon] = React.useState<any>(null);
  const [preview_file, setPreviewFile] = React.useState<any>(null);
  const [preview_icon, setPreviewIcon] = React.useState<any>(null);
  const [is_file, setIsFile] = React.useState<any>("0");
  const [is_icon, setIsIcon] = React.useState<any>("0");

  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    console.log(fname);
    let flx = e.target.files[0];
    formData.append(fname, flx);
    console.log(flx, formData);
    if (fname === "file") {
      setFile(flx);
      console.log(flx);
      setPreviewFile(URL.createObjectURL(flx)); // Would see a path?
      setIsFile("1");
    } else if (fname === "icon") {
      setIcon(flx);
      console.log(flx);
      setPreviewIcon(URL.createObjectURL(flx)); // Would see a path?
      setIsIcon("1");
    }
  };

  const handleDate = (name: string, value: any) => {
    console.log(name, value);
    const dxr = new Date(value).toISOString();
    //const fnx = new Date(dxr);
    setMeta({ ...meta, [name]: dxr });

    console.log(meta);
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/admin/events">Events</Link>
                    <span>New Event</span>
                  </Breadcrumbs>
                  <h2>New Event</h2>
                </div>
              </div>
            </div>

            <div className="py30 px20">
              <div className="px10 pb10">
                <div style={{ padding: "20px" }}>
                  <div className={" input "}>
                    <label>Event Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handleInputChange}
                      placeholder={"Event Title "}
                    />
                  </div>
                  <div className={" input "}>
                    <label>Event Theme</label>
                    <input
                      type="text"
                      className="form-control"
                      name="theme"
                      onChange={handleInputChange}
                      placeholder={"Event Theme "}
                    />
                  </div>
                  <div className="input">
                    <label className="zHigh">Event Sub Theme</label>
                    <div className="relative block">
                      <DefaultEditor
                        className="form-control"
                        value={sub_theme_content}
                        placeholder="Event Sub Theme"
                        onChange={onSubThemeChange}
                      />
                    </div>
                  </div>
                  <div className="input mt5">
                    <label>Select Event Category</label>
                    <select
                      name="cat_id"
                      className="form-control"
                      onChange={handleInputChange}
                    >
                      <option value="">Select Event Category</option>
                      {categories.map((item: any, i: number) => (
                        <option value={item.id} key={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="banner-section">
                    {preview_file && (
                      <div className="image_preview">
                        <img
                          className=""
                          src={preview_file}
                          alt="preview Image"
                        />
                      </div>
                    )}
                    <div className={loading ? " input iconed " : " input "}>
                      <label>Event Banner Picture</label>
                      <input
                        type="file"
                        className="form-control"
                        name="file"
                        onChange={handleFileChange}
                        placeholder={"Event Banner "}
                      />
                    </div>
                  </div>

                  <div className="input">
                    <label className="zHigh">Event Summary</label>
                    <div className="relative block">
                      <DefaultEditor
                        className="form-control"
                        value={summary}
                        placeholder="Event Summary"
                        onChange={onSummaryChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row-resp align-items-center py20">
                    <div className={" input spacer mr5"}>
                      <label>Event State</label>
                      <input
                        type="text"
                        className="form-control"
                        name="location_state"
                        onChange={handleInputChange}
                        placeholder={"Event State"}
                      />
                    </div>
                    <div className={" input spacer"}>
                      <label>Event Venue</label>
                      <input
                        type="text"
                        className="form-control"
                        name="location_venue"
                        onChange={handleInputChange}
                        placeholder={"Event Venue"}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row-resp align-items-center pb10">
                    <div className="input spacer mr5">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3} sx={{ width: "100%" }}>
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={meta.start_date}
                            /*  onChange={(e) =>
                              setMeta({ ...meta, start_date: e })
                            } */
                            onChange={(e) => handleDate("start_date", e)}
                            renderInput={(params: any) => (
                              <TextField {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>

                    <div className="input spacer">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3} sx={{ width: "100%" }}>
                          <DesktopDatePicker
                            label="End Date"
                            inputFormat="MM/dd/yyyy"
                            value={meta.end_date}
                            /*onChange={(e) => setMeta({ ...meta, end_date: e })}*/
                            onChange={(e) => handleDate("end_date", e)}
                            renderInput={(params: any) => (
                              <TextField {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="flex flex-row-resp">
                    <div className={" input spacer mr5"}>
                      <label>Physical Registration Fee</label>
                      <input
                        type="number"
                        className="form-control"
                        name="physical_price"
                        onChange={handleInputChange}
                        placeholder={"Physical Registration Fee"}
                      />
                    </div>
                    <div className={" input spacer"}>
                      <label>Virtual Registration Fee</label>
                      <input
                        type="number"
                        className="form-control"
                        name="virtual_price"
                        onChange={handleInputChange}
                        placeholder={"Virtual Registration Fee"}
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? "Working..." : " Save "}
                  </Button>
                </div>
              </div>

              <CustomModal data={toast} />
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventNew;
