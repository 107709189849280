import React from "react";
import { Outlet, useLocation, useParams, NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import Tooltip from "@mui/material/Tooltip";
import Add from "@mui/icons-material/Add";
import { CheckCircleOutline, Home, Pending } from "@mui/icons-material";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0" }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const EventList = (props: any) => {
  console.log(props);
  const { launchNew } = props;
  console.log(" events page Renders");
  let val = props.data;
  const [events, setEvent] = React.useState<any[]>([]);
  const [result_loaded, setResultLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  let params = useParams();
  const location = useLocation();
  const pageName = location.pathname;
  const [offset, setOffset] = React.useState<number>(0);
  const [isParam, setParam] = React.useState(false);
  const [ticket_modal_open, setNewModalOpen] = React.useState(false);
  const parts = location.pathname.split("/");
  const base = "/" + parts[1] + "/";
  React.useEffect(() => {
    const isParam = params.ticketId ? true : false;
    console.log("IS PARAM::: ", isParam);
    console.log("pageName::: ", pageName);
    setParam(isParam);
    if (
      (!isParam && pageName === "/admin/events") ||
      (!isParam && pageName === "/admin/events")
    ) {
      doAjax(offset);
    }
  }, [params]); //componentDidMount

  const doAjax = (offset: number) => {
    setLoading(true);
    setResultLoaded(false);
    HttpService.postHeader("events", {
      offset: offset,
      limit: 20,
      mode: "all",
      is_admin: true,
    }).then(
      (result) => {
        setLoading(false);
        console.log(result);
        if (Array.isArray(result)) {
          let newRes = [...events, ...result];
          setEvent(newRes);
        } else {
          setEvent([]);
        }
        setResultLoaded(true);
      },
      (error) => {
        setLoading(false);
        setResultLoaded(true);
        setError(error.message);
        setEvent([]);
      }
    ); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    doAjax(newOffset);
  };

  const launchNewModal = () => {
    setNewModalOpen(true);
  };

  const newModalClose = (data: any = false) => {
    setNewModalOpen(false);
    if (data) {
      events.unshift(data);
    }
  };

  const [tabIndex, setTabIndex] = React.useState(0);
  const tabChange = React.useCallback(
    (event: any, newValue: any) => {
      console.log(newValue);
      setTabIndex(newValue);
    },
    [tabIndex]
  );

  const { published, unpublished } = { published: [], unpublished: [] };

  const tabs: any[] = [
    {
      is_published: 1,
      label: `Unpublished`,
      icon: <Pending />,
    },
    {
      is_published: 0,
      label: `Published`,
      icon: <CheckCircleOutline />,
    },
  ];

  if (!isParam) {
    return (
      <>
        <div
          className="page-top-intro flex px10 py10 align-items-center 
      justify-content-center"
        >
          <h3 className="py0 my0 px0 mx0">
            <i className="fas fa-eventspaper"></i> All Events
          </h3>
          <div className="spacer"></div>
          <div>
            <Tooltip title="Create New">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={launchNew}
              >
                <Add />
                <span className="sm-hide-inline"> Add Event</span>
              </Button>
            </Tooltip>
          </div>
        </div>
        <Divider />

        <Box sx={{ width: "100%", typography: "body1" }}></Box>

        {events.length > 0 && (
          <div className="pxy10">
            <Card sx={{ p: "0", m: "0" }}>
              <Tabs
                value={tabIndex}
                onChange={tabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                aria-label="scrollable auto tabs example"
              >
                {tabs.map((itm: any, ind: number) => (
                  <Tab
                    key={ind}
                    icon={itm.icon}
                    iconPosition="start"
                    label={itm.label}
                  />
                ))}
              </Tabs>
              <List
                sx={{
                  p: "0",
                  m: "0",
                  // selected and (selected + hover) states
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: "red",
                    "&, & .MuiListItemIcon-root": {
                      color: "pink",
                    },
                  },
                  // hover states
                  "& .MuiListItemButton-root:hover": {
                    bgcolor: "#b7080d",
                    "&, & .MuiListItemIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                {events.map((item: any, index: number) => (
                  <>
                    {item.is_published === tabIndex && (
                      <>
                        <ListItem
                          disablePadding
                          button
                          key={index}
                          divider={true}
                          component={NavLink}
                          to={`${base}events/p/${item.url}`}
                        >
                          <ListItemButton>
                            <ListItemAvatar>
                              <Avatar
                                variant="square"
                                sx={{
                                  width: 50,
                                  height: 50,
                                  mr: "10px",
                                  borderRadius: "8px",
                                }}
                                alt={`${item.title} `}
                                src={
                                  item.thumb
                                    ? process.env
                                        .REACT_APP_SERVER_FILES_DOMAIN +
                                      item.thumb
                                    : "{`/images/logo.png`}"
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={<h4>{item.title}</h4>}
                              secondary={
                                <>
                                  <div className="">
                                    <span>{item.cat_title}</span>
                                  </div>
                                  <span className="date-spanx">
                                    <DatePipe value={item.create_date * 1000} />
                                  </span>
                                </>
                              }
                            ></ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </>
                    )}
                  </>
                ))}
              </List>
              {result_loaded && events.length === 0 && (
                <div className="result-error">
                  <span>
                    <i className="fas fa-exclamation-triangle"></i>
                  </span>
                  <h3> No Events found!</h3>
                </div>
              )}
            </Card>
          </div>
        )}
        {loading && (
          <>
            <div className="pxy10">
              <Card sx={{ m: "0", p: "0" }}>
                <PlaceHolder type="list" />
                <Divider />
                <PlaceHolder type="list" />
                <Divider />
                <PlaceHolder type="list" />
                <Divider />
                <PlaceHolder type="list" />
                <Divider />
                <PlaceHolder type="list" />
                <Divider />
                <PlaceHolder type="list" />
              </Card>
            </div>
          </>
        )}
        <div className="flex justify-content-center align-items-center py20">
          <Button
            disabled={loading}
            size="large"
            variant="contained"
            onClick={loadMore}
          >
            {loading ? "Loading..." : "Load More"}
          </Button>
        </div>
      </>
    );
  } else {
    return <Outlet />;
  }
};

export default EventList;
