import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import Collapse from "@mui/material/Collapse";
import Settings from "@mui/icons-material/Settings";
import Dashboard from "@mui/icons-material/Dashboard";
import HttpService from "../../services/HttpService";
import "./Header.css";
import LoggedHeader from "./LoggedHeader";
import NavDropIcon from "../templates/NavDropIcon";
import HeaderSearch from "./HeaderSearch";
import MobileNav from "./MobileNav";

const Header = () => {
  const [isTogged, setIsTogged] = React.useState(false);
  const page = useLocation().pathname;
  const logx = AuthService.isLogged();
  const usr = AuthService.getCurrentUser();
  const [isLogged, setIsLogged] = React.useState(logx);

  const closeNav = () => {
    setNavData({ ...nav_data, onopen: false });
  };
  const [nav_data, setNavData] = React.useState<any>({
    ononpen: false,
    onclose: closeNav,
    isLogged: isLogged,
  });
  const navigate = useNavigate();

  const doLogout = () => {
    AuthService.logout().then(() => {
      console.log("Session Cleared...");
      setIsLogged(false);
      window.location.href = "/";
      return;
    });
  };

  const toggleNav = () => {
    setNavData({
      ...nav_data,
      isLogged: isLogged,
      onopen: true,
      onclose: closeNav,
    });
    setIsTogged((cur) => !cur);
  };

  const doBoth = () => {
    doLogout();
    toggleNav();
  };

  const showMobileNav: boolean = isTogged ? true : false;
  const pages: any[] = [
    { path: "/account/dashboard", component: "Dashboard", icon: <Dashboard /> },
    {
      path: "/account/settings",
      component: "Account Settings",
      icon: <Settings />,
    },
  ];
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [search, setSearch] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [properties, setProperties] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (loaded) {
      setProperties([]);
    }
  }, []);

  const handleInputChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setSearch({ ...search, [name]: value });
    console.log(search);
    setProperties([]);
    setLoading(false);
    setLoaded(false);
  };

  const searchProperties = () => {};

  const resetSearch = () => {
    setLoaded(false);
    setLoading(false);
    setProperties([]);
    setSearch({ ...search, keyword: "" });
  };

  const redir = (link: string) => {
    return navigate(`/${link}`);
  };
  return (
    <React.Fragment>
      <section className="top-sticky z-omega">
        <header className="header-main">
          <div className="logo-top">
            <div className="logo spacer">
              <NavLink to="/">
                <img src={`/images/logo.png`} alt="ARN Logo" />
              </NavLink>
            </div>

            <span className="resp-nav">
              <a onClick={toggleNav}>
                {!isTogged ? (
                  <i className="fas fa-bars"></i>
                ) : (
                  <i className="fas fa-close"></i>
                )}
              </a>
            </span>
          </div>

          <nav>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/events">Events</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">Contact&nbsp;Us</NavLink>
              </li>
            </ul>
          </nav>
          <div className={"top-cta top-t"}>
            {!isLogged && (
              <>
                <Link to="/register" className="sign-up">
                  <span>Register</span>
                </Link>
                <Link to="/login" className="login">
                  <span>Log In</span>
                </Link>
              </>
            )}
            {isLogged && <LoggedHeader doLogout={doLogout} usr={usr} />}
          </div>
          {/**top-cta-ends */}
        </header>
        <section className="lower-section">
          <Collapse in={isTogged}>
            <MobileNav
              isLogged={isLogged}
              toggleNav={toggleNav}
              doLogout={doLogout}
            />
          </Collapse>
        </section>
      </section>
    </React.Fragment>
  );
};

export default Header;
