import React from "react";
import Divider from "@mui/material/Divider";

export default function SettingsPage() {
  return (
    <>
      <div
        className="page-top-intro flex px20 py10 align-items-center 
      justify-content-center"
      >
        <div className="px10">
          <h3 className="py0 my0 px0 mx0">
            <i className="fas fa-cogs"></i> Site Settings
          </h3>
        </div>
        <div className="spacer"></div>
        <div>-</div>
      </div>
      <Divider />
      <div className="pxy10">SETTINGS</div>
    </>
  );
}
