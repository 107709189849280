import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import Add from "@mui/icons-material/Add";
import TransactionsDetails from "./TransactionsDetails";
import TransactionsList from "./TransactionsList";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import TransactionsNew from "./TransactionsNew";

const Tickets = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/transactions/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<TransactionsList />} />
        <Route path="/" element={<TransactionsList />} />
        <Route path="/p/:ticketId" element={<TransactionsDetails />} />
        <Route path="/a/new" element={<TransactionsNew />} />
      </Routes>
    </React.Fragment>
  );
};

export default Tickets;
