import React from "react";
import { Link, NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import HttpService from "../../../services/HttpService";
import DurationPipe from "../../../pipes/DurationPipe";

const BookingsList = () => {
  const [offset, setOffset] = React.useState<number>(0);
  const [bookings, setBookings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listBookings(offset);
  }, []);

  const listBookings = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("events", {
      offset: offset,
      limit: 12,
      mode: "user-bookings",
    })
      .then(
        (result) => {
          console.log(result);
          if (result) {
            setBookings(result);
          } else {
            setBookings([]);
          }
        },
        (error) => {
          setBookings([]);
        }
      )
      .finally(() => {
        setTimeout(() => {}, 4000);
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container pxy20-resp">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/account/dashboard">Dashboard</Link>
                    <span>All Bookings</span>
                  </Breadcrumbs>
                  <h2 className="mt20">All Bookings</h2>
                </div>
              </div>
            </div>

            <div className="pxy0">
              {loaded && (
                <List
                  sx={{
                    p: "0",
                    m: "0",
                    // selected and (selected + hover) states
                    "&& .Mui-selected, && .Mui-selected:hover": {
                      bgcolor: "red",
                      "&, & .MuiListItemIcon-root": {
                        color: "pink",
                      },
                    },
                    // hover states
                    "& .MuiListItemButton-root:hover": {
                      bgcolor: "#b7080d",
                      "&, & .MuiListItemIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                >
                  {bookings.map((item: any, index: number) => (
                    <ListItem
                      disablePadding
                      button
                      key={index}
                      divider={true}
                      component={NavLink}
                      to={`/account/bookings/p/${item.id}`}
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar
                            variant="square"
                            sx={{
                              width: 50,
                              height: 50,
                              mr: "10px",
                              borderRadius: "8px",
                            }}
                            alt={`${item.title} `}
                            src={
                              item.thumb
                                ? process.env.REACT_APP_SERVER_FILES_DOMAIN +
                                  item.thumb
                                : "{`/images/logo.png`}"
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<h4>{item.title}</h4>}
                          secondary={
                            <>
                              <div className="">
                                <span className="date-spanx">
                                  <DurationPipe
                                    end={item.start_date * 1000}
                                    start={item.end_date * 1000}
                                  />
                                </span>
                              </div>
                            </>
                          }
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default BookingsList;
