import React from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  AddModerator,
  DirectionsOutlined,
  MoneyOutlined,
  TimerOutlined,
} from "@mui/icons-material";

import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import AuthService from "../../../services/AuthService";
import BookingModal from "../../templates/BookingModal";
import PlaceHolder from "../../templates/PlaceHolder";

const EventDetail = () => {
  let params: any = useParams();
  const { decodeHtml } = processHtml;
  const usr = AuthService.getCurrentUser();
  const cur_user = AuthService.getCurrentUser();
  const [events, setEvent] = React.useState<any>({ related_events: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<any>("");
  const [isParam, setParam] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.url ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchEvent(params.url);
    }
  }, [params]);

  const fetchEvent = (url: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ url: url, mode: "details" }, "events")
      .then(
        (result) => {
          console.log(result);
          if (result.title && result.title !== "") {
            document.title = result.title + "";
            setEvent(result);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Event not found";
            setEvent({
              id: 1,
              title: "Event does not exist",
              content: `The event you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong events url.`,
              create_date: now,
              related_events: [],
            });
          }
        },
        (error) => {
          setEvent({ related_events: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const [meta, setMeta] = React.useState<any>({});
  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
    console.log(message);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (!cur_user["isLogged"]) {
      formData.append("email", meta.email);
      formData.append("name", meta.name);
    } else {
      const xname = cur_user["firstname"] + " " + cur_user["surname"];
      formData.append("email", cur_user["email"]);
      formData.append("name", xname);
    }
    formData.append("comment", message);
    formData.append("event_id", events.id);
    formData.append("mode", "save-comment");
    console.log(formData);
    setLoading(true);
    setLoaded(false);
    HttpService.postFormHeader("events", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const [show_comment, setShow] = React.useState<boolean>(false);

  const togComment = (): void => {
    setShow(!show_comment);
  };

  const closeBook = () => {
    setBook({ onopen: false });
    setPayStart(false);
  };
  const [book, setBook] = React.useState<any>({
    onopen: false,
    onclose: closeBook,
    message: "",
  });
  const [pay_start, setPayStart] = React.useState(false);
  const launchBooking = () => {
    setPayStart(true);
    setBook({
      ...book,
      onopen: true,
      event_id: events.id,
      event_title: events.title,
      email: usr.email,
      url: events.url,
      virtual_price:
        typeof events.virtual_price === "string"
          ? parseInt(events.virtual_price)
          : events.virtual_price,
      physical_price:
        typeof events.physical_price === "string"
          ? parseInt(events.physical_price)
          : events.physical_price,
      phone: usr.phone,
      public_key: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      name: `${usr.firstname} ${usr.surname}`,
      reference: "",
      title: " Book event",
      onclose: closeBook,
    });
  };
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <SeoModule
        title={events.title}
        description={events.summary}
        name={"ARIN"}
        page_image={process.env.REACT_APP_SERVER_FILES_DOMAIN + events.photo}
        page_url={process.env.REACT_APP_SERVER_DOMAIN + events.url}
        type="Event"
      />

      <section className="dashboard-pane bg-diff">
        <div className="container pb10">
          {loaded && (
            <Card sx={{ borderRadius: "0" }}>
              <div className="page-head bg-grax">
                <div className="flex flex-row-resp align-items-center">
                  <div className="inline-block pxy20 spacer">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Link to="/">Home</Link>
                      <Link to="/events">Events</Link>
                    </Breadcrumbs>
                    <h2 className="mt10">{events.title}</h2>
                    <div>
                      <i className="fas fa-timer"></i>
                      <DurationPipe
                        start={events.start_date * 1000}
                        end={events.end_date * 1000}
                      />
                    </div>
                  </div>

                  <span className="px20 sm-hide-inline">
                    <Avatar
                      variant="square"
                      sx={{
                        width: 140,
                        height: 90,
                        borderRadius: "8px",
                      }}
                      alt={`${events.title} `}
                      src={
                        process.env.REACT_APP_SERVER_FILES_DOMAIN + events.thumb
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="pxy0 bg-differ">
                <div className=" pxy15">
                  <div className="relative">
                    <div className="highlighted-container">
                      <div className="highlight-banner">
                        <img
                          src={
                            process.env.REACT_APP_SERVER_FILES_DOMAIN +
                            events.photo
                          }
                          alt="events banner"
                        />
                      </div>
                    </div>

                    <div className="highlight-details">
                      {/*  <div className="h1">{events.title}</div> */}
                      <div className="meta-span align-items-center">
                        <span>
                          <i className="fas fa-calendar"></i>{" "}
                          <DurationPipe
                            start={events.start_date * 1000}
                            end={events.end_date * 1000}
                          />
                        </span>

                        <span className="txt px5"></span>
                        <span className="spacer"></span>
                        <span className="">
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={launchBooking}
                            size="large"
                            color="warning"
                          >
                            REGISTER
                          </Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pb10">
                  <Divider />
                  <ListItem divider>
                    <ListItemText>
                      <h3>Event Theme</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(events.theme),
                        }}
                      ></div>
                    </ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText>
                      <h3>Event Sub Theme</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(events.sub_theme),
                        }}
                      ></div>
                    </ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText>
                      <h3>Event Summary</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHtml(events.summary),
                        }}
                      ></div>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText>
                      <h3>Event Location</h3>
                      <div>
                        {events.location_venue}, {events.location_state}
                      </div>
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemText>
                      <h3>Event Duration</h3>
                      <div>
                        <DurationPipe
                          start={events.start_date * 1000}
                          end={events.end_date * 1000}
                        />
                      </div>
                    </ListItemText>
                  </ListItem>
                </div>
                <Divider />
                <Card sx={{ m: "0", p: "0" }}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <MoneyOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <h3>Physical Attendance</h3>
                          <div className="">
                            <Currency value={events.physical_price} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <MoneyOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <h3>Virtual Attendance</h3>
                          <div className="">
                            <Currency value={events.virtual_price} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <DirectionsOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <h3>Venue Directions</h3>
                          <div>
                            <a href="/">Open Map</a>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <TimerOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <h3>Event Duration</h3>
                          <div>
                            <DurationPipe
                              start={events.start_date * 1000}
                              end={events.end_date * 1000}
                            />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <TimerOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <h3>Event Duration</h3>
                          <div>
                            <DurationPipe
                              start={events.start_date * 1000}
                              end={events.end_date * 1000}
                            />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <ListItem divider>
                        <ListItemIcon>
                          <AddModerator sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div>
                            <Button
                              variant="contained"
                              onClick={launchBooking}
                              size="large"
                              color="warning"
                            >
                              REGISTER NOW
                            </Button>
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="wrapped label tabs example"
                    >
                      <Tab value="one" label="Event Timetable" wrapped />
                      <Tab value="two" label="Speakers" />
                      <Tab value="three" label="Organizers" />
                    </Tabs>
                  </Box>
                </Card>
              </div>
            </Card>
          )}
          {loading && <PlaceHolder type="event-detail" />}
        </div>
      </section>

      <CustomModal data={toast} />
      {pay_start && <BookingModal data={book} />}
    </React.Fragment>
  );
};

export default EventDetail;
