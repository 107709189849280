import React from "react";
import { NavLink, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventListTemplate from "../../templates/EventListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

const EventsSorted = (props: any) => {
  const { event_mode } = props;
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState(false);
  const [evMode, setEvMode] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    console.log("::params::", params);
    const isParam = params.eventMode ? true : false;
    setParam(isParam);
    if (isParam) {
      setEvMode(params.eventMode);
      listEvent(params.eventMode, offset);
    }
  }, [params]);

  const listEvent = (mode: string, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 12, mode: mode }, "events")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result)) {
            let newRes = [...events, ...result];
            setEvent(newRes);
          } else {
            setEvent([]);
          }
        },
        (error) => {
          setEvent([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(evMode, newOffset);
  };
  return (
    <React.Fragment>
      <section className="page-main">
        <section className="page-top">
          {/**/} <div className="page-top-cover"></div>
          <div className="container flex align-items-center z-high py20">
            <div className="page-info">
              <h2>Events</h2>
            </div>
          </div>
        </section>
        <div className="container py30">
          <EventListTemplate events={events} />
        </div>
        {loading && (
          <>
            <div className="pxy20">
              <Card className="pxy20">
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
              </Card>
            </div>
          </>
        )}
        <div className="flex justify-content-center align-items-center py20">
          <Button
            disabled={loading}
            size="large"
            variant="contained"
            onClick={loadMore}
          >
            {loading ? "Loading..." : "Load More"}
          </Button>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventsSorted;
