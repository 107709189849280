import React from "react";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/HttpService";
import * as processHtml from "../../services/processHtml";
import useFetchSiteSettings from "../../hooks/useFetchSiteSettings";
import "./Footer.css";
const Footer = () => {
  const { decodeHtml } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [categories, setCategories] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const { settings } = useFetchSiteSettings();

  React.useEffect(() => {
    listCategories(offset);
  }, []);

  const listCategories = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post(
      { offset: offset, limit: 120, mode: "list-categories-grouped" },
      "news"
    )
      .then(
        (result) => {
          if (Array.isArray(result)) {
            setCategories(result);
          } else {
            setCategories([]);
          }
        },
        (error) => {
          setCategories([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <section className="partners-wrapper">
        <div className="partners">
          <div
            dangerouslySetInnerHTML={{
              __html: decodeHtml(settings.footer_advert_message),
            }}
          ></div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <div className="footer-wrapper">
            <div className="col-block px20 spacer">
              <div className="specimen">
                <div className="imager">
                  <img src="/images/logo2.png" alt="Footer Logo" />
                </div>
              </div>
              <div className="desc">
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(settings.footer_message),
                  }}
                ></div>
              </div>
              <div className="social-brands">
                <div>Find Us:</div>
                <NavLink to="/">
                  <i className="fa-brands fa-twitter"></i>
                </NavLink>
                <NavLink to="/">
                  <i className="fa-brands fa-facebook"></i>
                </NavLink>
                <NavLink to="/">
                  <i className="fa-brands fa-linkedin"></i>
                </NavLink>
              </div>
            </div>
            {categories.map((item: any, index: number) => (
              <div className="col-block" key={index + 1}>
                <ul>
                  {categories[index].map((itx: any, i: number) => (
                    <li key={itx.id}>
                      <NavLink to={`/category/${itx.url}`}>{itx.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div className="col-block">
              <ul>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/terms">Terms & Conditions</NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="terms-policy">
            <p>&copy; ARN 2023</p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
