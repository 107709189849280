import React from "react";
import { Outlet, useLocation, Link, NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MailOutline from "@mui/icons-material/MailOutline";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import DatePipe from "../../../pipes/DatePipe";
import { HdrOffSelect, MoneyOutlined } from "@mui/icons-material";
import Currency from "../../../pipes/Currency";

const TransactionsList = (props: any) => {
  console.log(" Ticket page Renders");
  let val = props.data;
  const [transactions, setTransactions] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [offset, setOffset] = React.useState<number>(0);

  React.useEffect(() => {
    doAjax(offset);
  }, []); //componentDidMount

  const doAjax = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("transactions", {
      offset: offset,
      limit: 32,
      mode: "user-transactions",
    })
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            let newRes = [...transactions, ...result.data];
            setTransactions(newRes);
          } else {
            setTransactions([]);
          }
        },
        (error) => {
          setTransactions([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container pxy20-resp">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/account/dashboard">Dashboard</Link>
                    <span>All transactions</span>
                  </Breadcrumbs>
                  <h2 className="mt20">All transactions</h2>
                </div>
              </div>
            </div>

            <div className="pxy0">
              {loaded && (
                <List
                  sx={{
                    p: "0",
                    m: "0",
                    // selected and (selected + hover) states
                    "&& .Mui-selected, && .Mui-selected:hover": {
                      bgcolor: "red",
                      "&, & .MuiListItemIcon-root": {
                        color: "pink",
                      },
                    },
                    // hover states
                    "& .MuiListItemButton-root:hover": {
                      bgcolor: "#b7080d",
                      "&, & .MuiListItemIcon-root": {
                        color: "white",
                      },
                    },
                  }}
                >
                  {transactions.map((item: any, index: number) => (
                    <ListItem
                      disablePadding
                      button
                      key={index}
                      divider={true}
                      component={NavLink}
                      to={`/account/transactions/p/${item.id}`}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <MoneyOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary={<h4>{item.title}</h4>}
                          secondary={
                            <>
                              <div className="">
                                <span className="date-spanx">
                                  <Currency value={item.amount} />
                                </span>
                              </div>
                            </>
                          }
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TransactionsList;
