import React from "react";
import LoginForm from "./LoginForm";
import SeoModule from "../../../services/SeoModule";
import LoginModule from "./LoginModule";

const Login = () => {
  return (
    <React.Fragment>
      <SeoModule
        title="ARN - Login"
        description="Login Page"
        name="ARN"
        type="Event Events"
      />
      <section className="page-main">
        <section className="page-top bg-grax">
          <div className="page-info">
            <h2>Login</h2>
          </div>
        </section>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20">
          <div className="login-pane">
            {" "}
            <LoginModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
