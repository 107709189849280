import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CustomModal from "../../templates/CustomModal";
import SeoModule from "../../../services/SeoModule";
import { GoogleLogin, useGoogleLogin, googleLogout } from "@react-oauth/google";

import axios from "axios";
import { Facebook, Google, LogoutOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const LoginForm = ({
  submit_handler,
  loading,
  intro_message,
}: {
  submit_handler: any;
  loading: boolean;
  intro_message: string;
}) => {
  const [form, setForm] = React.useState<any>({});
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
  };
  const [user, setUser] = React.useState<any>(null);

  return (
    <React.Fragment>
      <div>
        {intro_message && intro_message !== "" && (
          <h2 className="text-center txt-lg px20 pb30">{intro_message}</h2>
        )}

        <div className="input iconed">
          <label>Username</label>
          <input
            type="text"
            name="login"
            className="form-control"
            placeholder="Username"
            onChange={handleInput}
          />
          <span className="input-icon">
            <i className="fas fa-user"></i>
          </span>
        </div>
        <div className="input iconed">
          <label>Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter password"
            onChange={handleInput}
          />
          <span className="input-icon">
            <i className="fas fa-lock"></i>
          </span>
        </div>
        <div className="flex flex-row align-items-center">
          <Button
            onClick={() => submit_handler(form)}
            size="large"
            disabled={loading}
            variant="contained"
            type="submit"
          >
            {loading ? "Working..." : "Login"}
          </Button>
          <span className="spacer"></span>
          <span className="pl5">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </span>
        </div>
      </div>
      <div className="py20 text-center">
        Don't have an account?{" "}
        <Link to="/register">Create an account</Link>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
