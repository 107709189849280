import axios from "axios";
import authHeader from "./AuthHeaders";

const API_URL = process.env.REACT_APP_SERVER_DOMAIN_API;
type headerType = {
  Authorization?: string;
  Bearer?: string;
};
class HttpService {
  auh: headerType = authHeader();

  findAccount(load: any) {
    const url = API_URL + "/auth";
    return axios.post(url, load).then((response) => {
      return response.data;
    });
  }

  post(data: any, endpoint: string) {
    const url = API_URL + endpoint;
    return axios.post(url, data).then((response) => {
      return response.data;
    });
  }
  get(endpoint: string) {
    return axios.post(API_URL + endpoint).then((response) => {
      return response.data;
    });
  }
  postHeader(endpoint: string, data: any) {
    return axios
      .post(API_URL + endpoint, data, { headers: this.auh })
      .then((response) => {
        return response.data;
      });
  }
  postForm(endpoint: string, data: any) {
    const obh = { "content-type": "multipart/form-data" };
    return axios
      .post(API_URL + endpoint, data, { headers: obh })
      .then((response) => {
        return response.data;
      });
  }
  postFormHeader(endpoint: string, data: any) {
    const objh = { "content-type": "multipart/form-data" };
    const hrs = { ...this.auh, ...objh };
    return axios
      .post(API_URL + endpoint, data, { headers: hrs })
      .then((response) => {
        return response.data;
      });
  }
  getHeader(endpoint: string) {
    return axios
      .get(API_URL + endpoint, { headers: this.auh })
      .then((response) => {
        let resp = response.data;
        return resp;
      });
  }
}
export default new HttpService();
