import React from "react";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventListTemplate from "../../templates/EventListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { NavLink } from "react-router-dom";
import DatePipe from "../../../pipes/DatePipe";
import PromptRegister from "../../templates/PromptRegister";
import DurationPipe from "../../../pipes/DurationPipe";
import { Typography } from "@mui/material";
const EventUpcoming = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 4, mode: "featured" }, "events")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result)) {
            setEvent(result);
          } else {
            setEvent([]);
          }
        },
        (error) => {
          setEvent([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };
  return (
    <React.Fragment>
      <Card sx={{ flexGrow: "1" }}>
        <div className="feature-card-header">
          <i className="fas fa-calendar"> </i> &nbsp;UPCOMING EVENTS
        </div>
        <Divider />
        {loaded && (
          <>
            {events.map((item: any, index: number) => (
              <ListItem
                disablePadding
                button
                key={index}
                divider={index < events.length - 1 ? true : false}
                component={NavLink}
                to={`/event/${item.url}`}
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      sx={{
                        width: 70,
                        height: 70,
                        mr: "10px",
                        borderRadius: "8px",
                      }}
                      alt={`${item.title} `}
                      src={
                        item.thumb
                          ? process.env.REACT_APP_SERVER_FILES_DOMAIN +
                            item.thumb
                          : "{`/images/logo.png`}"
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        component={"h4"}
                        sx={{ lineHeight: "1.2", fontWeight: "600" }}
                      >
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        component={"div"}
                        className="flex flex-row pxy0"
                      >
                        <span className="date-span">
                          <i className="fas fa-calendar"></i>{" "}
                          <DurationPipe
                            start={item.start_date * 1000}
                            end={item.end_date * 1000}
                          />
                        </span>
                      </Typography>
                    }
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
        {loading && <PlaceHolder type="featured" />}
      </Card>
    </React.Fragment>
  );
};

export default EventUpcoming;
