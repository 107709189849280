import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import EventList from "./EventList";
import EventNew from "./EventNew";
import EventDetails from "./EventDetails";
import EventEdit from "./EventEdit";

const Event = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/admin/events/a/new`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<EventList launchNew={launchNew} />} />
        <Route path="/" element={<EventList launchNew={launchNew} />} />
        <Route path="/p/:eventsUrl" element={<EventDetails />} />
        <Route path="/a/new" element={<EventNew />} />
        <Route
          path="/e/:eventsUrl"
          element={<EventEdit launchNew={launchNew} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default Event;
