import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventListTemplate from "../../templates/EventListTemplate";

const EventList = (props: any) => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 24, mode: "all" }, "events")
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result)) {
            let newRes = [...events, ...result];
            setEvent(result);
          } else {
            setEvent([]);
          }
        },
        (error) => {
          setEvent([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py0 bordered">
          <div className="page-main">
            <div className=" py0">
              <div className="page-head bg-grax">
                <div className="flex flex-row-resp">
                  <div className="inline-block pxy20 spacer">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Link to="/">Home</Link>
                    </Breadcrumbs>
                    <h2 className="mt10">All Events</h2>
                  </div>
                </div>
              </div>
              <div className="pxy20-resp  bg-differ">
                {loaded && (
                  <EventListTemplate
                    loading={loading}
                    loaded={loaded}
                    events={events}
                    max_grid={4}
                  />
                )}
                {loading && <PlaceHolder type="events" max_grid={4} />}

                <div className="flex justify-content-center align-items-center py20">
                  <Button
                    disabled={loading}
                    size="large"
                    variant="contained"
                    onClick={loadMore}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventList;
