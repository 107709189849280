import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import TextField from "@mui/material/TextField";
import { DefaultEditor } from "react-simple-wysiwyg";
import Stack from "@mui/material/Stack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Add from "@mui/icons-material/Add";

const EventEdit = (props: any) => {
  const { launchNew } = props;
  console.log("New Edit Renders");
  let navigate = useNavigate();
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [events, setEvent] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);
  const [content, setContent] = React.useState<any>(false);
  const [title, setTitle] = React.useState<any>(false);
  const [id, setId] = React.useState<any>(false);
  const [cat_id, setCatId] = React.useState<any>(false);
  const [summary, setSummary] = React.useState<any>("");
  const [theme, setTheme] = React.useState<any>(false);
  const [sub_theme, setSubTheme] = React.useState<any>("");
  const [physical_price, setPhysicalPrice] = React.useState<any>(false);
  const [virtual_price, setVirtualPrice] = React.useState<any>(false);
  const [location_venue, setLocationVenue] = React.useState<any>(false);
  const [location_state, setLocationState] = React.useState<any>(false);
  const [start_date, setStartDate] = React.useState<any>(false);
  const [end_date, setEndDate] = React.useState<any>(false);
  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [categories_fetched, setCategoriesFetched] =
    React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<any[]>([]);
  //
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });
  const [meta, setMeta] = React.useState<any>({
    title: "",
    content: "",
    id: 0,
    related_events: [],
  });

  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.eventsUrl ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchEvent(params.eventsUrl);
    }
  }, []);

  const fetchEvent = (url: string) => {
    console.log(url);
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("events", {
      url: url,
      is_admin: true,
      mode: "details",
    })
      .then(
        (result) => {
          setLoading(false);
          console.log(":::", result);
          if (!title) {
            setSummary(result.summary);
            setTitle(result.title);
            setId(result.id);
            setCatId(result.cat_id);
            setTheme(result.theme);
            setSubTheme(result.sub_theme);
            setPhysicalPrice(result.physical_price);
            setVirtualPrice(result.virtual_price);
            setLocationState(result.location_state);
            setLocationVenue(result.location_venue);
            setStartDate(toUnix(result.start_date));
            setEndDate(toUnix(result.end_date));
            setPreview(
              process.env.REACT_APP_SERVER_FILES_DOMAIN + result.photo
            );
          }
        },
        (error) => {
          setEvent({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };

  const handleInputChange = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      if (name === "title") {
        setTitle(value);
      }
    },
    [events]
  );

  const onSubThemeChange = (e: any) => {
    setSubTheme(e.target.value);
    console.log(content);
  };

  const onSummaryChange = (e: any) => {
    setSummary(e.target.value);
    console.log(summary);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("summary", summary);
    formData.append("mode", "edit");
    formData.append("is_new_file", new_file);
    formData.append("file", file);
    formData.append("title", title);
    formData.append("id", id);
    formData.append("cat_id", cat_id);
    formData.append("theme", theme);
    formData.append("sub_theme", sub_theme);
    formData.append("physical_price", physical_price);
    formData.append("virtual_price", virtual_price);
    formData.append("location_venue", location_venue);
    formData.append("location_state", location_state);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    setLoaded(false);
    HttpService.postForm("events", formData)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.mess });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
              navigate(`/admin/events/p/${resp.url}`);
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  React.useEffect(() => {
    if (!categories_fetched) {
      fetchCats();
    }
  }, []);

  const fetchCats = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("events", { mode: "list-categories" })
      .then(
        (resp) => {
          console.log(resp);
          if (Array.isArray(resp)) {
            setCategories(resp);
            setCategoriesFetched(true);
          } else {
            setCategories([]);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }; //fetchCats
  const toUnix = (dt: string | number) => {
    if (typeof dt === "string") {
      dt = parseInt(dt);
    }
    return new Date(dt * 1000).toISOString();
  };

  const handleDate = (name: string, value: any) => {
    //console.log(name, value);
    const dxr = new Date(value).toISOString();
    const fnx = new Date(dxr);
    if (name === "start_date") {
      setStartDate(dxr);
    } else if (name === "end_date") {
      setEndDate(dxr);
    }
  };

  return (
    <>
      <div
        className="page-top-intro flex px10 py10 align-items-center 
      justify-content-center"
      >
        <h3 className="py0 my0 px0 mx0">
          <i className="fas fa-eventspaper"></i> Edit Event
        </h3>
        <div className="spacer"></div>
        <div>
          <Tooltip title="Edit Event">
            <Button
              onClick={launchNew}
              size="small"
              variant="contained"
              color="primary"
            >
              <Add />
              <span className="sm-hide-inline"> Add Event</span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <Divider />

      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Link to="/admin/events">Events</Link>

        <Link to={`/admin/events/p/${events.url}`}>{events.title}</Link>
      </Breadcrumbs>
      <div className="px10 pb10">
        <Card sx={{ p: "0", m: "0" }}>
          <div style={{ padding: "20px" }}>
            <div className={loading ? " input iconed " : " input "}>
              <label>Event Title</label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={"Event Title "}
              />
              {loading && (
                <span className="input-icon">
                  <i className="fas fa-refresh fa-spin"></i>
                </span>
              )}
            </div>
            <div className={" input "}>
              <label>Event Theme</label>
              <input
                type="text"
                className="form-control"
                name="theme"
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                placeholder={"Event Theme "}
              />
            </div>
            <div className="input">
              <label className="zHigh">Event Sub Theme</label>
              <div className="relative block">
                <DefaultEditor
                  className="form-control"
                  value={sub_theme}
                  placeholder="Event Sub Theme"
                  onChange={onSubThemeChange}
                />
              </div>
            </div>
            <div className="input">
              <label>Select Category</label>
              <select
                name="cat_id"
                className="form-control"
                onChange={(e) => setCatId(e.target.value)}
              >
                <option value="">Select Category</option>
                {categories.map((item: any, i: number) => (
                  <option
                    value={item.id}
                    key={item.id}
                    selected={item.id === cat_id ? true : false}
                  >
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div className={loading ? " input iconed " : " input "}>
                <label>Change Banner Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"Event Title "}
                />
              </div>
            </div>
            <div className="input">
              <label className="zHigh">Event Summary</label>
              <div className="relative block">
                <DefaultEditor
                  className="form-control"
                  value={summary}
                  placeholder="Event Summary"
                  onChange={onSummaryChange}
                />
              </div>
            </div>
            <div className="flex flex-row-resp align-items-center py20">
              <div className={" input spacer mr5"}>
                <label>Event State</label>
                <input
                  type="text"
                  className="form-control"
                  name="location_state"
                  value={location_state}
                  onChange={(e) => setLocationState(e.target.value)}
                  placeholder={"Event State"}
                />
              </div>
              <div className={" input spacer"}>
                <label>Event Venue</label>
                <input
                  type="text"
                  className="form-control"
                  name="location_venue"
                  value={location_venue}
                  onChange={(e) => setLocationVenue(e.target.value)}
                  placeholder={"Event Venue"}
                />
              </div>
            </div>
            <div className="flex flex-row-resp align-items-center pb10">
              <div className="input spacer mr5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3} sx={{ width: "100%" }}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/dd/yyyy"
                      value={start_date} /* 
                      onChange={(e) => setStartDate(e?.toDateString())} */
                      onChange={(e) => handleDate("start_date", e)}
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>

              <div className="input spacer mr5">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3} sx={{ width: "100%" }}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/dd/yyyy"
                      value={end_date}
                      onChange={(e) => handleDate("end_date", e)}
                      /*  onChange={(e) => setEndDate(e?.toDateString())} */
                      renderInput={(params: any) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex flex-row-resp">
              <div className={" input spacer mr5"}>
                <label>Physical Registration Fee</label>
                <input
                  type="number"
                  className="form-control"
                  name="physical_price"
                  value={physical_price}
                  onChange={(e) => setPhysicalPrice(e.target.value)}
                  placeholder={"Physical Registration Fee"}
                />
              </div>
              <div className={" input spacer"}>
                <label>Virtual Registration Fee</label>
                <input
                  type="number"
                  className="form-control"
                  name="virtual_price"
                  value={virtual_price}
                  onChange={(e) => setVirtualPrice(e.target.value)}
                  placeholder={"Virtual Registration Fee"}
                />
              </div>
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Edit Event "}
            </Button>
            {/**/}{" "}
          </div>
        </Card>
      </div>

      <CustomModal data={toast} />
    </>
  );
};

export default EventEdit;
