import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import BookingsList from "./BookingsList";
import BookingDetails from "./BookingDetails";
/* import BookingsNew from "./BookingsNew";
import BookingsEdit from "./BookingsEdit";
 */
const Bookings = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<BookingsList />} />
        <Route path="/" element={<BookingsList />} />
        <Route path="/p/:bookingId" element={<BookingDetails />} />
        {/*<Route path="/a/new" element={<BookingsNew />} />
        <Route
          path="/e/:eventsUrl"
          element={<BookingsEdit launchNew={launchNew} />}
        /> */}
      </Routes>
    </React.Fragment>
  );
};

export default Bookings;
