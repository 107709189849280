import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import ConfirmModal from "../../templates/ConfirmModal";
import PromptRegister from "../../templates/PromptRegister";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  ApartmentOutlined,
  FlagOutlined,
  HotelOutlined,
  MoneyOutlined,
  PinDropOutlined,
  PinOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import BookingCertificate from "./BookingCertificate";

const BookingDetails = (props: any) => {
  let navigate = useNavigate();
  let params: any = useParams();
  const { decodeHtml, truncateWord } = processHtml;
  const [booking, setBooking] = React.useState<any>({ related_booking: [] });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [isParam, setParam] = React.useState<any>(false);
  const [publish_mode, setPublish] = React.useState("publish");
  const [feature_mode, setFeature] = React.useState("feature");
  React.useEffect(() => {
    console.log("::params::", params);
    const isParam = params.bookingId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchBooking(params.bookingId);
    }
  }, [params]);

  const fetchBooking = (id: string) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post(
      { id: id, mode: "booking-details", is_admin: true },
      "events"
    )
      .then(
        (result) => {
          console.log(result);
          if (result.title && result.title !== "") {
            document.title = result.title + "";
            setBooking(result);
          } else {
            const now = new Date().getTime() / 1000;
            document.title = "Booking not found";
            setBooking({
              id: 1,
              title: "Booking does not exist",
              content: `The booking you are looking for does not exist. 
              It might not be published yet, deleted or you typed a wrong booking url.`,
              create_date: now,
              related_booking: [],
            });
            const pub_mode =
              result.is_published === 1 ? "unpublish" : "publish";
            const feat_mode =
              result.is_featured === 1 ? "unfeature" : "feature";
            setPublish(pub_mode);
            setFeature(feat_mode);
          }
        },
        (error) => {
          setBooking({ related_booking: [] });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const launchEdit = (url: any) => {
    return navigate(`/admin/booking/e/${url}`);
  };
  const doPublish = (data: any) => {
    setLoading(true);
    setLoaded(false);
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      title: `${data.action} booking`,
      loading_text: `${data.action}ing...`,
    });
    HttpService.post({ id: data.id, mode: data.action }, "events")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          setConfirm({
            ...confirm,
            onopen: true,
            id: data.id,
            title: " Booking",
            loading_text: result.message,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  const closeConfirm = () => {
    setConfirm({ ...confirm, onopen: false });
  };
  const [confirm, setConfirm] = React.useState<any>({
    onopen: false,
    onaccept: doPublish,
    onclose: closeConfirm,
    loading_text: "",
    title: "",
    id: "",
    action: "",
  });
  const launchAction = (data: any) => {
    console.log(data);
    setConfirm({
      ...confirm,
      onopen: true,
      id: data.id,
      action: data.action,
      title: data.action + " booking",
      loading_text: `Are you sure you want to ${data.action} this booking?`,
    });
  };

  const [tabIndex, setTabIndex] = React.useState(0);
  const tabChange = React.useCallback(
    (event: any, newValue: any) => {
      console.log(newValue);
      setTabIndex(newValue);
    },
    [tabIndex]
  );

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py0">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp align-items-center">
                <div className="inline-block pxy20 spacer">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/account/dashboard">Dashboard</Link>
                    <Link to="/account/bookings">Bookings</Link>
                  </Breadcrumbs>
                  <h2 className="mt10">{booking.title}</h2>
                  <div>
                    <i className="fas fa-timer"></i>
                    <DurationPipe
                      start={booking.start_date * 1000}
                      end={booking.end_date * 1000}
                    />
                  </div>
                </div>

                <span className="pr20 sm-hide-inline">
                  <Avatar
                    variant="square"
                    sx={{
                      width: 140,
                      height: 90,
                      borderRadius: "8px",
                    }}
                    alt={`${booking.title} `}
                    src={
                      process.env.REACT_APP_SERVER_FILES_DOMAIN + booking.thumb
                    }
                  />
                </span>
              </div>
            </div>

            <div className="mb0">
              <Card sx={{ m: "0", p: "0" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem divider>
                      <ListItemIcon>
                        <ApartmentOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <h3>Attendance Mode</h3>
                        <div>{booking.attendance_mode}</div>
                      </ListItemText>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem divider>
                      <ListItemIcon>
                        <MoneyOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <h3>Amount Paid</h3>
                        <div>
                          <Currency value={booking.amount} />
                        </div>
                      </ListItemText>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem divider>
                      <ListItemIcon>
                        <FlagOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <h3>Payment Reference</h3>
                        <div>{booking.reference}</div>
                      </ListItemText>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem>
                      <ListItemIcon>
                        <TimerOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <h3>Event Duration</h3>
                        <div>
                          <DurationPipe
                            start={booking.start_date * 1000}
                            end={booking.end_date * 1000}
                          />
                        </div>
                      </ListItemText>
                    </ListItem>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem>
                      <ListItemIcon>
                        <TimerOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <h3>Event Duration</h3>
                        <div>
                          <DurationPipe
                            start={booking.start_date * 1000}
                            end={booking.end_date * 1000}
                          />
                        </div>
                      </ListItemText>
                    </ListItem>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <ListItem>
                      <ListItemIcon>
                        <HotelOutlined sx={{ fontSize: "40px" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <div>
                          <Button variant="contained">Add Hotel Booking</Button>
                        </div>
                      </ListItemText>
                    </ListItem>
                  </Grid>
                </Grid>
              </Card>
            </div>

            <Box sx={{ width: "100%" }}>
              <Tabs
                value={tabIndex}
                onChange={tabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                aria-label="scrollable auto tabs example"
              >
                <Tab value={0} label="Details" />
                <Tab value={1} label="Timetable" />
                <Tab value={2} label="Speakers" />
                <Tab value={3} label="Certificate" />
              </Tabs>
              {tabIndex === 1 && (
                <section className="pxy20">Time Table</section>
              )}
              {tabIndex === 2 && <section className="pxy20">Speakers</section>}
              {tabIndex === 3 && loaded && (
                <section className="px20">
                  <BookingCertificate data={booking} />
                </section>
              )}
            </Box>
            {tabIndex === 0 && (
              <section className="pb10">
                <Divider />
                <ListItem divider>
                  <ListItemText>
                    <h3>Event Theme</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(booking.theme),
                      }}
                    ></div>
                  </ListItemText>
                </ListItem>

                <ListItem divider>
                  <ListItemText>
                    <h3>Event Sub Theme</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(booking.sub_theme),
                      }}
                    ></div>
                  </ListItemText>
                </ListItem>

                <ListItem divider>
                  <ListItemText>
                    <h3>Event Summary</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(booking.summary),
                      }}
                    ></div>
                  </ListItemText>
                </ListItem>
                <ListItem divider>
                  <ListItemText>
                    <h3>Event Location</h3>
                    <div>
                      {booking.location_venue}, {booking.location_state}
                    </div>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemText>
                    <h3>Event Duration</h3>
                    <div>
                      <DurationPipe
                        start={booking.start_date * 1000}
                        end={booking.end_date * 1000}
                      />
                    </div>
                  </ListItemText>
                </ListItem>
              </section>
            )}
          </Card>
        </div>
      </section>
      <ConfirmModal data={confirm} />
    </React.Fragment>
  );
};

export default BookingDetails;
