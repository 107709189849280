import React from "react";
import RegisterForm from "./RegisterForm";
import SeoModule from "../../../services/SeoModule";
import RegisterModule from "./RegisterModule";

const Register = () => {
  return (
    <React.Fragment>
      <SeoModule
        title="ARN - Register"
        description="Register Page"
        name="ARN"
        type="Event Events"
      />
      <section className="page-main">
        <section className="page-top bg-grax">
          <div className="page-info">
            <h2>Create Account</h2>
          </div>
        </section>

        <div className="flex flex-col flex-column justify-content-center align-items-center py20">
          <div className="login-pane">
            <RegisterModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Register;
