import React from "react";
import { Link, NavLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DatePipe from "../../pipes/DatePipe";
import * as processHtml from "../../services/processHtml";
import HttpService from "../../services/HttpService";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Close, ShareOutlined } from "@mui/icons-material";
import { platform } from "os";

const EventShareTemplate = (props: any) => {
  console.log("share temp:: ", props);
  const { event } = props;
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [shares, setShares] = React.useState(event.share_num);
  const [dislikes, setDislikes] = React.useState(event.dislike_num);
  const [info, setInfo] = React.useState({});

  const sendShare = (platform: string) => {
    setLoading(true);
    setLoaded(false);
    const load = {
      platform: platform,
      event_id: event.id,
      mode: "save_event_share",
    };
    console.log(load);
    HttpService.postHeader("events", load)
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setShares(shares + 1);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
    setAnchorEl(null);
  };

  const afterShare = (platform: string) => {
    sendShare(platform);
  };

  const shareUrl = process.env.REACT_APP_SERVER_DOMAIN + "/event/" + event.url;
  return (
    <React.Fragment>
      <span className="pl0">
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  paddingTop: "7px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "7px",
                  flex: "1",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:before": paper,
                }}
              >
                <Tooltip title="Share on Twitter">
                  <span className="px5">
                    <TwitterShareButton
                      url={shareUrl}
                      title={event.title}
                      onShareWindowClose={() => afterShare("twitter")}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                  </span>
                </Tooltip>
                <Tooltip title="Share on Facebook">
                  <span className="px5">
                    <FacebookShareButton
                      className={"react-btn"}
                      url={shareUrl}
                      hashtag={"#event"}
                      quote={event.title}
                      title={event.title}
                      onShareWindowClose={() => afterShare("facebook")}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </span>
                </Tooltip>
                <Tooltip title="Share on Whatsapp">
                  <span className="px5">
                    <WhatsappShareButton
                      className={"react-btn"}
                      url={shareUrl}
                      title={event.title}
                      onShareWindowClose={() => afterShare("whatsapp")}
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </span>
                </Tooltip>
                <Tooltip title="Share on Linkedin">
                  <span className="px5">
                    <LinkedinShareButton
                      url={shareUrl}
                      title={event.title}
                      className="react-btn"
                      onShareWindowClose={() => afterShare("linkedin")}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </span>
                </Tooltip>
              </Paper>
            </Fade>
          )}
        </Popper>
        <IconButton
          className={"react-btn share-btn"}
          onClick={handleClick("top")}
        >
          <ShareOutlined />
        </IconButton>
        <span className="count-label">{shares}</span>
      </span>
    </React.Fragment>
  );
};

const paper = {
  content: '""',
  display: "block",
  position: "absolute",
  top: "calc(100% - 5px)",
  right: "45%",
  width: 12,
  height: 12,
  bgcolor: "#ddd",
  transform: "translateY(-5%) rotate(45deg)",
  zIndex: 0,
};

export default React.memo(EventShareTemplate);
