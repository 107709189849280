import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import SeoModule from "../../../services/SeoModule";
import * as processHtml from "../../../services/processHtml";
import HomeCats from "./HomeCats";
import useFetchCategories from "../../../hooks/useFetchCategories";
import Grid from "@mui/material/Grid";
import EventUpcoming from "../Event/EventUpcoming";
import EventHighlighted from "../Event/EventHighlighted";
import "./Home.css";

const Home = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [tab, setTab] = React.useState<number>(0);
  const { categories, loading_categories, loaded_categories } =
    useFetchCategories();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <SeoModule
        title="Association of Radiologists in  Nigeria - Home"
        description="Home curated news from across the globe"
        name="Association of Radiologists in  Nigeria"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Event & Info"
      />
      <Header />
      <section className="home-main">
        <div className="home-banner">
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexGrow: "1", alignContent: "stretch" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              sx={{ display: "flex", flexGrow: "1" }}
            >
              <div className="highlighted-container">
                <EventHighlighted />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ display: "flex", flexGrow: "1" }}
            >
              <EventUpcoming />
            </Grid>
          </Grid>
        </div>
      </section>
      <HomeCats />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
