import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import EventLikeTemplate from "./EventLikeTemplate";
import EventShareTemplate from "./EventShareTemplate";
import DurationPipe from "../../pipes/DurationPipe";
import * as processHtml from "../../services/processHtml";
import {
  ArrowCircleDownOutlined,
  ArrowDownwardOutlined,
  ArrowDropDownCircleOutlined,
  CommentOutlined,
  Grid4x4,
  ListAltOutlined,
} from "@mui/icons-material";
import { Avatar, Collapse, Tooltip } from "@mui/material";

const EventListTemplate = (props: any) => {
  const { events, loading, loaded, max_grid } = props;
  const [list, setList] = React.useState<any[]>(events);
  const base_url = props.base_url ? "/" + props.base_url : "/";
  const { decodeHtml } = processHtml;
  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...list];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setList(mutd);
  };

  const ucfirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <React.Fragment>
      <div className="">
        {loaded && (
          <>
            <Grid container spacing={2}>
              {/**<Grid item xs={12} sm={6} md={3} key={item.id}> */}
              {list.map((item: any, index: number) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={max_grid ? max_grid : 3}
                  key={item.id}
                >
                  <div className={"news-card"}>
                    {item.is_thumb && (
                      <div className="thumb_pane">
                        <NavLink to={`${base_url}event/${item.url}`}>
                          <div className="news_thumb">
                            <img
                              src={
                                process.env.REACT_APP_SERVER_FILES_DOMAIN +
                                item.thumb
                              }
                              alt="news banner"
                            />
                          </div>
                        </NavLink>
                        <div className="cat-span">
                          <NavLink to={`/category/${item.cat_url}`}>
                            {item.cat_title}
                          </NavLink>
                        </div>
                      </div>
                    )}

                    <div className="news_content_space">
                      <div className="flex flex-row align-items-center">
                        <div className="date-space spacer">
                          <DurationPipe
                            start={item.start_date * 1000}
                            end={item.end_date * 1000}
                          />{" "}
                        </div>

                        <span className="texr">
                          {!item.is_togged ? (
                            <Tooltip title="View Event Theme">
                              <IconButton
                                size="small"
                                onClick={() => togView(index, true)}
                              >
                                <i className="fas fa-chevron-down"></i>
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Hide  Event Theme">
                              <IconButton
                                size="small"
                                onClick={() => togView(index, false)}
                              >
                                <i className="fas fa-chevron-up"></i>
                              </IconButton>
                            </Tooltip>
                          )}
                        </span>
                      </div>
                      <h3>
                        <NavLink to={`${base_url}event/${item.url}`}>
                          {item.title}
                        </NavLink>
                      </h3>

                      <Collapse in={item.is_togged}>
                        <div
                          className="news-summary"
                          dangerouslySetInnerHTML={{
                            __html: decodeHtml(item.theme),
                          }}
                        ></div>
                      </Collapse>
                    </div>

                    <div className="react-span px10">
                      <span className="spacer">
                        <EventShareTemplate event={item} />
                      </span>
                      <span>
                        <IconButton>
                          <CommentOutlined />
                        </IconButton>
                        {item.comment_num}
                      </span>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(EventListTemplate);
