import React from "react";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventListTemplate from "../../templates/EventListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
const EventCategories = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [offset, setOffset] = React.useState<number>(0);
  const [news, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 12, mode: "all" }, "news")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (Array.isArray(result)) {
            let newRes = [...news, ...result];
            setEvent(newRes);
          } else {
            setEvent([]);
          }
        },
        (error) => {
          setEvent([]);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(false);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };
  return (
    <React.Fragment>
      <section className="page-main">
        <section className="page-top">
          <div className="page-info">
            <h2>
              <h2>Event Categories</h2>
            </h2>
          </div>
        </section>

        <div className="container py30">{/*  <HomeCategories /> */}</div>

        <div className="container">
          <div className="section-intro">
            <h2>Recent Events</h2>
          </div>
          <EventListTemplate news={news} />
        </div>
        {loading && (
          <>
            <div className="pxy20">
              <Card className="pxy20">
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
                <Divider />
                <PlaceHolder type="events" />
              </Card>
            </div>
          </>
        )}
        <div className="flex justify-content-center align-items-center py20">
          <Button
            disabled={loading}
            size="large"
            variant="contained"
            onClick={loadMore}
          >
            {loading ? "Loading..." : "Load More"}
          </Button>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventCategories;
