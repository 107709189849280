import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  Slide,
  DialogContent,
  DialogActions,
  Card,
  Dialog,
  Divider,
  IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Download } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CertificateModal = (props: any) => {
  const modal = props.data;
  const exitPage = () => {
    modal.onclose();
  };
  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={modal.onopen}
      onClose={modal.onclose}
      scroll={"paper"}
      aria-labelledby={"Me"}
    >
      <DialogContent sx={{ p: "0", m: "0" }}>
        <section className="cert-div">
          {modal.preview && (
            <img className="" src={modal.preview} alt="preview Image" />
          )}
        </section>
      </DialogContent>

      <Divider />
      <DialogActions>
        <span className="spacer"></span>
        <Button
          variant="contained"
          size="medium"
          onClick={exitPage}
          color="warning"
        >
          Exit
        </Button>

        <a
          href={modal.preview}
          className="file-download-btn"
          download={`${modal.reference}.jpg`}
        >
          <Download /> DOWNLOAD
        </a>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CertificateModal);
