import React from "react";
import { Link, useParams } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import SeoModule from "../../../services/SeoModule";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";

const VerifyCert = () => {
  const params = useParams();
  const { decodeHtml } = processHtml;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [cert_confirmed, setCertConfirmed] = React.useState<boolean>(false);
  const [confirmed_html, setConfirmedHtml] = React.useState<string>("");
  //
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (params.certId) {
      console.log(params.certId);
      confirmCert(params.certId);
    }
  }, [params]);

  const confirmCert = (certId: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postForm("auth", {
      mode: "verify-certificate",
      cert_id: certId,
    })
      .then(
        (response) => {
          let rsp = response;
          console.log("conf code :::: ", rsp);
          if (rsp.status === 1) {
            setConfirmedHtml(rsp.html);
          } else {
            setCertConfirmed(false);
          }
        }, //resPonse ends//
        (error) => {
          setModal({
            ...modal_data,
            onopen: true,
            message: error.message,
            onclose: modalClose,
          });
          setConfirmedHtml(`We are experiencing a network issue
           and cannot verify this Certificate at the moment`);
          setCertConfirmed(false);
        } //error ends//
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <SeoModule
        title="ARN - Verify Registration"
        description="Verify Registration"
        name="ARN"
        type="Event Events"
      />

      <section className="dashboard-pane">
        <div className="container py30">
          <Card sx={{ borderRadius: "0" }}>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp align-items-center">
                <div className="inline-block pxy20 spacer">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/">Home</Link>
                  </Breadcrumbs>
                  <h2 className="mt10">Verify Certificate</h2>
                </div>
              </div>
            </div>

            <div className="mb0">
              <div className="l-pane">
                {loaded && cert_confirmed && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(confirmed_html),
                    }}
                  ></div>
                )}
                {loaded && (
                  <div
                    className="p0"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(confirmed_html),
                    }}
                  ></div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default VerifyCert;
