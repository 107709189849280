import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventListTemplate from "../../templates/EventListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
const EventCategory = (props: any) => {
  const params = useParams();
  const [loading_news, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState<number>(0);
  const [news, setEvent] = React.useState<any>({ data: [] });
  const [list, setList] = React.useState<any[]>([]);
  const [loaded_news, setLoaded] = React.useState<boolean>(false);

  const [isParam, setParam] = React.useState(false);
  const [catUrl, setCatUrl] = React.useState<any>(null);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const isParam = params.catUrl ? true : false;
    setParam(isParam);
    if (isParam) {
      setCatUrl(params.catUrl);
      listEvent(params.catUrl, 0);
    }
  }, [catUrl, params]);

  const listEvent = (cat_url: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post(
      { offset: offset, limit: 120, cat_url: cat_url, mode: "category" },
      "news"
    )
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setList(result.data);
          } else {
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(catUrl, newOffset);
  };
  return (
    <React.Fragment>
      <section className="dashboard-pane">
        <div className="container py10">
          <Card>
            <div className="page-head bg-grax">
              <div className="flex flex-row-resp">
                <div className="inline-block pxy20">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Link to="/">Home</Link>
                    <span>{news?.title || "Event Category"}</span>
                  </Breadcrumbs>
                  <div className="flex flex-row align-items-center mt10">
                    <h2>{news?.title || "Event Category"}</h2>
                    <span className="spacer"></span>
                    <span>--</span>
                  </div>
                </div>
              </div>
            </div>

            <div className=" pxy20">
              <EventListTemplate
                loaded={loaded_news}
                loading={loading_news}
                news={list}
              />
              {loading_news && <PlaceHolder type="events" />}
            </div>
          </Card>
        </div>
      </section>
    </React.Fragment>
  );
};

export default EventCategory;
