import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Contact from "./Contact/Contact";
import Home from "./Home/Home";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Event from "./Event/Event";
import EventDetail from "./Event/EventDetail";
import EventCategories from "./Event/EventCategories";
import Four0Four from "./Four0Four/Four0Four";
import OauthPage from "./Oauth/Oauth";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyRegistration from "./VerifyRegistration/VerifyRegistration";
import VerifyCert from "./VerifyCert/VerifyCert";
import "./Public.css";

const Public = () => {
  const page = useLocation()["pathname"];

  return (
    <React.Fragment>
      {page !== "/" && page !== "" && <Header />}
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password/:code" element={<ResetPassword />}></Route>
        <Route
          path="/verify-registration/:code"
          element={<VerifyRegistration />}
        ></Route>
        <Route path="/events/*" element={<Event />}></Route>
        <Route
          path="/verify-certificate/:certId"
          element={<VerifyCert />}
        ></Route>
        <Route path="/event/:url" element={<EventDetail />}></Route>
        <Route path="/categories" element={<EventCategories />}></Route>
        <Route path="/oauth-setup/:oauth" element={<OauthPage />}></Route>

        <Route
          path="*"
          element={
            <>
              <Four0Four />
            </>
          }
        />
      </Routes>
      {page !== "/" && page !== "" && <Footer />}
    </React.Fragment>
  );
};

export default Public;
