import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import EventListTemplate from "../../templates/EventListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
import useFetchCategories from "../../../hooks/useFetchCategories";

const HomeCats = (props: any) => {
  const [loading_event, setLoading] = React.useState(true);
  const [un_tabbed, setUntabbed] = React.useState(true);
  const [tab, setTab] = React.useState<number>(0);
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any>({ data: [] });
  const [loaded_event, setLoaded] = React.useState<boolean>(false);
  const { categories, loading_categories, loaded_categories } =
    useFetchCategories();

  console.log(categories);
  React.useEffect(() => {
    if (un_tabbed || tab === 0) {
      listEvent("all", 0);
    }
    if (loaded_categories && !un_tabbed && tab !== 0) {
      console.log("NEWS::::", categories[tab]?.url);
      listEvent(categories[tab]?.url, 0);
    } /*   */
    //categories.unshift({ title: "All", id: 0, url: "all" });
  }, [categories, tab]);

  const styles = {
    backgroundImage: "url(/images/radiology.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const navigate = useNavigate();
  const goTo = (url: string) => {
    return navigate(`category/${url}`);
  };

  const listEvent = (cat_url: any, offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post(
      { offset: offset, limit: 12, cat_url: cat_url, mode: "category" },
      "events"
    )
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result) {
            setEvent(result.data);
          } else {
            setEvent({ data: [] });
          }
        },
        (error) => {
          setEvent({ data: [] });
        }
      )
      .finally(() => {
        setTimeout(() => {}, 4000);
        setLoading(false);

        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(categories[tab]?.url, newOffset);
  };

  const [cat_togged, setCatTog] = React.useState<boolean>(false);
  const [active_cat, setActiveCat] = React.useState<string>("All Events");
  const togCat = () => {
    setCatTog(!cat_togged);
  };

  const setActive = (index: number) => {
    setTab(index);
    setActiveCat(categories[index]["title"]);
    setCatTog(!cat_togged);
    setUntabbed(false);
  };

  return (
    <React.Fragment>
      <section className="home-event">
        <ul className="home-category-bar">
          <div className="active-lane">
            <Link to={"#"}>{active_cat}</Link>
            <span className="spacer"></span>
            <Link to={"#"} onClick={togCat}>
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <Collapse in={cat_togged}>
            <div className="list-lane">
              <Grid container spacing={0}>
                {loaded_categories &&
                  categories.map((item: any, index: number) => (
                    <Grid item xs={6} sm={4} md={3} key={item.id}>
                      <Link
                        to={"#"}
                        className={` ${tab === index && `active `}`}
                        onClick={() => setActive(index)}
                      >
                        {item.title}
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Collapse>
        </ul>
        <div className="pxy20-resp bg-diff">
          {loaded_categories && loaded_event && (
            <EventListTemplate
              loading={loading_event}
              loaded={loaded_event}
              events={events}
            />
          )}
          {loading_event && <PlaceHolder type="events" />}
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomeCats;
