import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const RegisterForm = ({
  submit_handler,
  loading,
  intro_message,
}: {
  submit_handler: any;
  loading: boolean;
  intro_message: string;
}) => {
  const [form, setForm] = React.useState<any>({});
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
  };
  const [user, setUser] = React.useState<any>(null);

  return (
    <React.Fragment>
      <div>
        {intro_message && intro_message !== "" && (
          <h2 className="text-center txt-lg px20 pb30">{intro_message}</h2>
        )}

        <div className="pb10  pt20 STAGE1">
          <div className="input">
            <label>{"Firstname"}</label>
            <input
              className={" form-control "}
              name={"firstname"}
              type="text"
              required
              placeholder="Firstname"
              onChange={handleInput}
            />
          </div>

          <div className="input">
            <label>{"Surname"}</label>
            <input
              className={" form-control "}
              name={"surname"}
              type="text"
              required
              placeholder="Surname"
              onChange={handleInput}
            />
          </div>

          <div className="input">
            <label>
              Phone
              <sup className="boldest red" title="This field is required!">
                *
              </sup>
            </label>
            <input
              className={" form-control "}
              name={"phone"}
              type="text"
              required
              placeholder="Phone Number"
              onChange={handleInput}
            />
          </div>

          <div className="input">
            <label>
              {"Email"}
              <sup className="boldest red" title="This field is required!">
                *
              </sup>
            </label>
            <input
              className={" form-control "}
              name={"email"}
              type="text"
              required
              placeholder="Email Address"
              onChange={handleInput}
            />
          </div>

          <div className="input">
            <label>
              {"Password"}
              <sup className="boldest red" title="This field is required!">
                *
              </sup>
            </label>
            <input
              className={" form-control "}
              name={"password"}
              type="password"
              required
              placeholder="Password"
              onChange={handleInput}
            />
          </div>

          <div className="input">
            <label>
              {"Confirm Password"}
              <sup className="boldest red" title="This field is required!">
                *
              </sup>
            </label>
            <input
              className={" form-control "}
              name={"password2"}
              type="password"
              required
              placeholder="Confirm Password"
              onChange={handleInput}
            />
          </div>
        </div>

        <div className="pb20">
          <label>
            <input
              type={"checkbox"}
              onClick={handleInput}
              name="agree"
              style={{ fontSize: "1.5rem", padding: "10px" }}
            />{" "}
            Agree to our
            <a href="/terms"> Terms & conditions</a>
          </label>
        </div>
        <div className="flex flex-row align-items-center">
          <Button
            onClick={() => submit_handler(form)}
            size="large"
            disabled={loading}
            variant="contained"
            type="submit"
          >
            {loading ? "Working..." : "Register"}
          </Button>
          <span className="spacer"></span>
          <span className="pl5">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </span>
        </div>
      </div>
      <div className="py20 text-center">
        Already an account? <Link to="/login">Login</Link>
      </div>
    </React.Fragment>
  );
};

export default RegisterForm;
