import React from "react";
import { NavLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import CommentOutlined from "@mui/icons-material/CommentOutlined";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import DurationPipe from "../../../pipes/DurationPipe";
import PromptRegister from "../../templates/PromptRegister";
import PlaceHolder from "../../templates/PlaceHolder";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventShareTemplate from "../../templates/EventShareTemplate";

const EventHighlighted = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [events, setEvent] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    listEvent();
  }, []);

  const listEvent = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ mode: "highlighted" }, "events")
      .then(
        (result) => {
          setLoading(false);
          console.log("HIGHLIGHTED res:: ", result);
          if (result) {
            setEvent(result);
          } else {
            setEvent({});
          }
        },
        (error) => {
          setEvent({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      {loaded && (
        <>
          <div className="highlight-signal">
            <span className="signal-box">
              <span className="fa-stack signal-icon">
                <i className="fa-solid fa-circle pulse fa-stack-1x"></i>
                <i
                  className="fa-sharp fa-regular fa-circle  fa-stack-2x"
                  style={{ color: "Tomato" }}
                ></i>
              </span>

              <span className="signal-text px5">Now Live</span>
            </span>
          </div>
          <div className="highlight-details">
            <div className="h1">
              <NavLink to={`/event/${events.url}`}>{events.title}</NavLink>
            </div>
            <div className="meta-span">
              <span>
                <i className="fas fa-calendar"></i>{" "}
                <DurationPipe
                  start={events.start_date * 1000}
                  end={events.end_date * 1000}
                />
              </span>

              <span className="txt px5"></span>
              <span className="spacer"></span>
              <span className="">
                <span className="txt pr10">
                  <ShareOutlined />
                  <EventShareTemplate event={events} />
                </span>
                <span className="txt pl5">
                  <CommentOutlined /> {events.comment_num}
                </span>
              </span>
            </div>
          </div>
          <NavLink to={`/event/${events.url}`} className="highlight-banner">
            {loaded && (
              <>
                <img
                  src={process.env.REACT_APP_SERVER_FILES_DOMAIN + events.photo}
                  alt="events banner"
                />
              </>
            )}
            {loading && (
              <Skeleton
                sx={{ borderRadius: "4px", display: "block" }}
                variant="rectangular"
                height={"450px"}
                width={"100%"}
              />
            )}
          </NavLink>
        </>
      )}
      {loading && <PlaceHolder type="highlighted" />}
    </React.Fragment>
  );
};

export default EventHighlighted;
