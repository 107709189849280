import React from "react";
import {
  Slide,
  Card,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import AuthService from "../../services/AuthService";
import LoginModule from "../public/Login/LoginModule";
import RegisterModule from "../public/Register/RegisterModule";

const BlockerTemplate = (props: any) => {
  const { button_text, return_url, mode, full_width } = props;
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [view_mode, setViewMode] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [header_text, setHeaderText] = React.useState(
    "Have you registered before on this platform?"
  );
  const [modal, setModal] = React.useState({
    onopen: false,
    onclose: closeModal,
    title: "User Login",
  });
  const launchModal = () => {
    setModal({ ...modal, onopen: true, onclose: closeModal });
  };
  const [load, setLoad] = React.useState<any>({
    user_regged: false,
    user_logged: false,
    event_booked: false,
    event_paid: false,
    event_details_sent: false,
  });

  const usr = AuthService.getCurrentUser();

  return (
    <>
      <section className="pxy20">
        <h3 className="mb20">{header_text}</h3>
        {!view_mode && (
          <Card sx={{ m: "0", p: "0" }}>
            <ListItem divider disablePadding>
              <ListItemButton onClick={() => setViewMode("yes")}>
                <ListItemText>Yes, I have</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => setViewMode("no")}>
                <ListItemText>No, I have not</ListItemText>
              </ListItemButton>
            </ListItem>
          </Card>
        )}
        {view_mode === "yes" && <LoginModule return_url={return_url} />}
        {view_mode === "no" && <RegisterModule return_url={return_url} />}
      </section>
    </>
  );
};

export default React.memo(BlockerTemplate);
