import React from "react";
import Box from "@mui/material/Box";
import CircularProgress  from "@mui/material/CircularProgress";

const Loading = (props?: any) => {
  const { loading_text } = props;
  return (
    <>
      <Box
        sx={{
          p: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={60} />
        <div className="py20">
          <h3>{loading_text}</h3>
        </div>
      </Box>
    </>
  );
};

export default React.memo(Loading);
